import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import ColorDots from "../components/ColorDots/ColorDots"
import Container from "../components/Container/Container"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import Footer from "../components/Footer/Footer"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  console.log(markdownRemark)
  const { frontmatter, html, featuredImg } = markdownRemark
  return (
    <Layout>
      <div style={{ marginTop: "100px" }}>
        <Container thin>
          <h1>{frontmatter.title}</h1>
        </Container>
        <ColorDots style={{ margin: "50px 0" }}></ColorDots>
        <div style={{ width: "300px", height: "300px", margin: "0 auto" }}>
          <Img
            fluid={featuredImg?.childImageSharp?.fluid}
            alt={data.markdownRemark.frontmatter.featuredImgAlt}
          />
        </div>
        <Container thin style={{ marginTop: "50px" }}>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Container>
      </div>
      <EmailSignup></EmailSignup>
      <Footer></Footer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
